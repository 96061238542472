<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton btnTitle="Add New" :showBtn="false" :showAddNew="false" title="Pending GRN from PO"
                   @onClickAddNewButton="onClickAddNewButton"/>
      <div class="row match-height mt-1">
        <div class="col-12 col-lg-8 col-xl-9 col-xxl-7">
          <v-select
              placeholder="Select Supplier type"
              v-model="supplierId"
              :options="suppliers"
              label="supplier"
              :reduce="supplier => supplier.id"
          />
        </div>
      </div>
    </div>

    <ListTable/>
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";
import TitleButton from '@/components/atom/TitleButton'
import ListTable from '@/components/molecule/procurement/inventory/grn/pending/ListingTable'
import Pagination from '@/components/atom/Pagination'

let offset = ref(0);
let suppliers = ref([]);
let supplierId = ref(null);

function onClickAddNewButton() {
  //todo
}

function getPendingGrns() {
  //todo
}

function onPageChange() {
  //todo
}

</script>
