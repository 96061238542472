<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>SL no</th>
          <th>PO Date</th>
          <th>PO No</th>
          <th>PO Details</th>
          <th>PO- Total Qty</th>
          <th>GRN- Total Qty</th>
          <th>Due</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <td>1</td>
        <td>12/3/22</td>
        <td>#PO-24782</td>
        <td>Details</td>
        <td>2500.00</td>
        <td>1500.00</td>
        <td>1000.00</td>
        <td>GRN</td>
        </tbody>
      </table>
      <!-- <p v-if="!journals.length" class="text-center mt-5">No data</p> -->
    </div>
  </div>
</template>

<script setup></script>
